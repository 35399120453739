import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_LINK
axios.defaults.headers.common['Token'] = process.env.VUE_APP_SECURE_TOKEN
axios.defaults.headers.post['Token'] = process.env.VUE_APP_SECURE_TOKEN

export default {
  namespaced: true,
  state: {
    list: [],
    categories: [],
    choosenCategory: '',
    sortedList: [],
    headerCategories: [],
    storeControllInf: undefined
  },
  mutations: {
    SETLIST (state, payload) {
      state.list = payload.data
      state.categories = []
      for (var i = 0; i < state.list.length; i++) {
        if (!state.categories.includes(state.list[i].category)) {
          state.categories.push(state.list[i].category)
          
        } 
        if (!state.headerCategories.includes(state.list[i].category)) {
          state.headerCategories.push(state.list[i].category)
        } 
      }
    },
    SET_STORECONTROLL(state, paylaod) {
      state.storeControllInf = paylaod.controll
    },
  },
  actions: {
    getList ({ commit }) {
      return axios.get('/products/')
        .then((res) => {
          commit('SETLIST', { data: res.data })
          return res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    chooseCategory ({ commit }, data) {
      return axios.get(`/products/category/${data}`)
        .then((res) => {
          commit('SETLIST', { data: res.data, category: data })
          return res.data
        })
        .catch(err => {
          return err
        })
    },
    async getStoreControll({ commit }) {
      return await axios.get(`/controlStore/get`)
      .then((res) => {
        commit("SET_STORECONTROLL", { controll : res.data });
        return res.data
      })
      .catch(err => {
        console.log(err)
      })
    },
    async updateStoreStatus({ commit }, payload) {
      return await axios.patch(`/controlStore/`, { storeControll: payload.storeControllInf })
      .then((res) => {
        commit("SET_STORECONTROLL", { controll : res.data });
        return res.data
      })
      .catch(err => {
        console.log(err)
      })
    }
  },
}