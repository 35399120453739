<template>
    <div v-if='!currentLink.params.category && slides'>
      <p class="news">Новинки</p>
      <Splide :options="{ rewind: true }">
        <SplideSlide v-for="slide in slides" :key="slide">
          <img class="SplideSlide__item" :src="slide">
        </SplideSlide>
      </Splide>
    </div>
</template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import 'vue3-carousel/dist/carousel.css'
  import '@splidejs/vue-splide/css';
  export default defineComponent({
    name: 'TopContentSlider',
    components: {
      Splide,
      SplideSlide
    },
    computed: {
      currentLink () {
        return this.$route
      },
      slides () {
        return this.$store.state.storeControl?.storeControllInf?.newProducts
      }
    },
  })
  </script>
  
<style lang="sass">
.SplideSlide__item 
  max-height: 300px
  width: 100%
  object-fit: contain

@media screen and (max-width: 1000px)
  .navigation
      visibility: hidden
  .carousel__item
      width: 95%


.news
  padding: 20px 15px 
  font-weight: 700
  font-size: 22px
  line-height: 26px
</style>
  