<template>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="300" height="200" viewBox="210 -300 1167.000000 765.000000"
 preserveAspectRatio="xMidYMid meet" style='margin-top: -120px; margin-bottom: 25px'>

<g transform="translate(0.000000,765.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M4220 3940 l0 -1050 25 0 25 0 0 1050 0 1050 -25 0 -25 0 0 -1050z"/>
<path d="M2562 4687 c-6 -7 -19 -31 -29 -53 -15 -33 -23 -39 -48 -39 -29 0
-30 -2 -33 -50 -3 -63 20 -112 70 -144 l38 -24 -32 -20 c-18 -11 -34 -15 -35
-10 -10 30 -31 45 -52 38 -25 -8 -81 -80 -81 -105 0 -29 -11 -30 -35 -2 -21
24 -24 25 -30 9 -5 -11 2 -29 17 -50 23 -29 58 -90 58 -100 0 -2 -18 -9 -39
-16 -48 -14 -88 -59 -103 -117 -7 -24 -18 -44 -25 -44 -10 0 -13 -77 -13 -385
l0 -385 775 0 775 0 0 385 0 385 -45 0 c-55 0 -55 3 -20 120 29 97 30 118 11
165 l-15 34 37 7 c20 4 49 14 65 23 38 21 79 84 75 113 -3 22 -7 23 -73 23
-38 0 -78 -4 -87 -9 -15 -7 -16 -3 -11 28 4 28 2 36 -10 36 -10 0 -23 -25 -39
-77 l-24 -78 -61 0 c-76 -1 -112 -24 -168 -106 -41 -60 -55 -71 -55 -43 0 74
-70 168 -154 209 -44 21 -67 26 -118 24 -34 -1 -75 4 -89 11 -61 28 -120 -42
-86 -102 9 -16 17 -42 17 -56 0 -15 5 -44 11 -65 6 -22 9 -40 7 -42 -2 -1 -20
-5 -41 -8 -34 -5 -38 -4 -32 11 42 104 -15 208 -121 220 -41 4 -45 7 -39 26
35 112 28 159 -38 230 -46 49 -58 54 -75 33z m64 -72 c33 -44 42 -107 21 -155
-12 -27 -16 -30 -16 -14 -2 36 -29 91 -56 112 -25 20 -25 22 -11 56 8 20 19
36 25 36 5 0 22 -16 37 -35z m-96 -70 c35 -18 60 -62 60 -105 0 -36 -5 -37
-52 -5 -28 19 -39 35 -46 67 -13 62 -8 67 38 43z m1280 -139 c0 -7 -18 -30
-40 -50 -32 -29 -48 -36 -80 -36 -26 0 -40 4 -40 13 0 20 58 75 83 80 48 9 77
7 77 -7z m-851 -10 c13 -12 24 -12 58 -3 98 26 225 -55 257 -164 24 -78 21
-94 -20 -126 -20 -15 -51 -40 -67 -54 l-31 -26 -37 46 c-26 34 -54 55 -99 75
-70 32 -90 62 -90 141 0 32 -6 51 -20 65 -38 38 10 81 49 46z m-197 -47 c29
-15 48 -52 48 -90 0 -48 -32 -119 -63 -141 -23 -16 -25 -15 -62 24 -50 51 -87
70 -141 71 l-44 0 6 41 c8 48 17 62 55 82 63 32 153 38 201 13z m-298 -25 c12
-32 5 -90 -14 -122 -25 -41 -42 -37 -54 12 -16 62 47 165 68 110z m1158 -54
c38 -38 40 -42 35 -92 -2 -29 -15 -90 -28 -135 -23 -81 -24 -83 -55 -83 -41 0
-44 -9 -44 -131 l0 -99 -200 0 -200 0 -7 28 c-25 90 10 199 85 260 96 79 159
143 197 201 44 67 81 90 139 91 32 0 45 -7 78 -40z m-1029 -103 c40 -17 103
-86 128 -141 12 -26 19 -64 19 -103 l0 -63 -41 0 c-53 0 -109 -51 -109 -99 l0
-31 -95 0 -94 0 -3 113 -3 112 -67 3 c-63 3 -68 4 -68 26 0 46 41 89 103 107
32 9 74 30 94 46 61 47 83 52 136 30z m412 -56 c48 -22 103 -75 111 -106 3
-13 -2 -37 -13 -56 -14 -26 -18 -56 -18 -126 0 -51 -3 -93 -7 -93 -4 0 -14 16
-22 35 -14 33 -66 75 -94 75 -8 0 -25 9 -39 20 -30 24 -83 26 -123 5 l-28 -15
5 58 c4 42 0 70 -15 109 l-20 52 27 18 c67 46 167 56 236 24z m-645 -286 l0
-95 -65 0 -65 0 0 95 0 95 65 0 65 0 0 -95z m1343 18 c-1 -43 -2 -86 -3 -95 0
-15 -10 -18 -65 -18 l-65 0 0 95 0 95 68 0 67 0 -2 -77z m-805 -8 c2 -2 -8
-14 -22 -28 -14 -14 -28 -37 -32 -51 -6 -26 -34 -37 -34 -13 0 6 -3 28 -6 48
-6 33 -4 37 22 47 24 9 60 7 72 -3z m-154 -26 c17 -14 26 -30 26 -50 l0 -29
-70 0 -70 0 0 29 c0 32 39 71 70 71 10 0 29 -9 44 -21z m261 -34 c39 -38 32
-45 -50 -45 -82 0 -94 11 -55 50 28 28 75 26 105 -5z m697 -91 c2 -9 1 -41 0
-70 l-4 -54 -734 0 -734 0 0 70 0 70 734 0 c671 0 735 -1 738 -16z m-2 -224
l0 -70 -735 0 -735 0 0 70 0 70 735 0 735 0 0 -70z m3 -170 l1 -65 -737 -3
-737 -2 0 70 0 70 736 -2 736 -3 1 -65z"/>
<path d="M3040 4356 c0 -8 20 -22 45 -32 43 -16 135 -102 135 -125 0 -5 9 -9
20 -9 26 0 25 4 -5 55 -26 46 -76 88 -130 111 -43 18 -65 18 -65 0z"/>
<path d="M2613 4333 c-7 -2 -13 -11 -13 -19 0 -10 14 -14 51 -14 46 0 55 -4
86 -36 26 -28 37 -34 46 -25 15 15 -19 61 -64 83 -33 17 -80 22 -106 11z"/>
<path d="M3416 4150 c-21 -28 -47 -55 -57 -61 -24 -12 -25 -39 -2 -39 22 0
125 110 121 129 -6 29 -25 20 -62 -29z"/>
<path d="M3248 3998 c-49 -53 -80 -128 -76 -189 3 -60 28 -83 28 -27 0 62 29
138 71 183 21 23 39 47 39 54 0 21 -31 11 -62 -21z"/>
<path d="M2529 4129 c-11 -8 -10 -13 10 -29 27 -23 51 -26 51 -6 0 21 -44 46
-61 35z"/>
<path d="M2600 4059 c0 -7 10 -27 21 -46 12 -19 27 -53 34 -76 7 -26 19 -43
30 -45 15 -4 17 1 11 35 -13 69 -96 184 -96 132z"/>
<path d="M2853 4079 c-19 -5 -33 -16 -33 -25 0 -12 10 -14 60 -8 53 6 66 3
115 -21 57 -28 72 -28 62 -1 -16 41 -141 75 -204 55z"/>
<path d="M2252 3628 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 18 -1 22 -23 22
-22 0 -26 -4 -23 -22z"/>
<path d="M3614 3639 c-3 -6 0 -18 9 -26 18 -18 37 -9 37 18 0 21 -34 27 -46 8z"/>
<path d="M2263 3483 c-7 -3 -13 -13 -13 -23 0 -19 17 -30 38 -23 6 3 12 13 12
23 0 19 -17 30 -37 23z"/>
<path d="M3621 3477 c-16 -16 -14 -34 5 -41 21 -8 34 1 34 24 0 22 -23 33 -39
17z"/>
<path d="M2263 3313 c-19 -7 -16 -40 4 -47 21 -8 36 6 31 30 -3 19 -16 25 -35
17z"/>
<path d="M3622 3308 c-17 -17 -15 -35 5 -42 21 -8 36 6 31 30 -4 22 -20 28
-36 12z"/>
<path d="M4880 4460 c-102 -6 -137 -19 -181 -67 -53 -56 -63 -89 -63 -210 l-1
-113 -48 0 -48 0 3 -72 3 -73 45 -3 45 -3 -3 -244 -3 -245 86 0 85 0 0 245 0
245 95 0 95 0 0 75 0 75 -96 0 -97 0 5 96 c7 121 12 127 115 134 l76 5 19 69
c21 79 19 94 -14 92 -13 0 -66 -3 -118 -6z"/>
<path d="M5304 4076 c-154 -37 -254 -166 -254 -326 0 -165 104 -293 264 -327
75 -16 138 -8 194 24 41 23 42 23 42 3 0 -19 6 -20 85 -20 l85 0 0 320 0 320
-85 0 c-85 0 -85 0 -85 -26 l0 -25 -26 20 c-29 23 -115 52 -148 50 -12 0 -44
-6 -72 -13z m162 -181 c110 -65 109 -226 -2 -291 -105 -61 -230 4 -242 125 -6
64 2 92 35 132 53 63 136 76 209 34z"/>
<path d="M6163 4080 c-17 -4 -48 -17 -68 -29 -44 -26 -45 -26 -45 -1 0 19 -7
20 -90 20 l-90 0 0 -320 0 -320 90 0 90 0 0 188 c0 169 2 190 20 220 26 42 76
72 121 72 l35 0 19 78 c10 42 20 83 22 90 6 14 -50 15 -104 2z"/>
<path d="M6616 4076 c-21 -8 -43 -19 -49 -25 -16 -16 -37 -13 -37 4 0 12 -16
15 -85 15 l-85 0 0 -320 0 -320 85 0 85 0 0 166 c0 196 9 243 57 284 64 56
177 32 209 -45 10 -26 14 -81 14 -220 l0 -185 90 0 90 0 0 193 c0 173 2 195
20 224 38 63 140 85 194 42 51 -40 56 -62 56 -269 l0 -190 90 0 90 0 0 193 c0
222 -8 264 -68 342 -27 36 -57 60 -105 84 -58 30 -76 34 -139 34 -81 -1 -141
-21 -196 -67 l-32 -28 -37 29 c-82 62 -177 85 -247 59z"/>
<path d="M7760 4077 c-94 -27 -163 -89 -209 -187 -36 -79 -36 -201 1 -281 35
-76 88 -133 156 -166 47 -23 70 -28 133 -28 64 0 85 5 140 33 36 17 74 40 83
51 18 20 18 21 -10 45 -16 13 -42 36 -59 50 -25 21 -32 23 -45 12 -83 -73
-193 -56 -247 39 -44 78 -53 75 213 75 l236 0 -4 63 c-13 200 -206 346 -388
294z m150 -159 c36 -25 77 -74 68 -83 -3 -3 -69 -4 -147 -3 l-142 3 23 34 c47
69 136 91 198 49z"/>
<path d="M8522 4075 c-23 -7 -54 -20 -67 -29 -27 -18 -36 -20 -34 -8 5 29 -4
32 -86 32 l-85 0 0 -320 0 -321 88 3 87 3 5 195 c4 175 7 198 25 222 28 36 51
49 104 57 l46 6 17 70 c25 104 24 105 -19 104 -21 0 -57 -6 -81 -14z"/>
<path d="M8855 4068 c-142 -50 -185 -206 -87 -309 37 -38 69 -51 228 -89 69
-17 71 -71 3 -91 -49 -15 -105 -1 -166 42 l-41 29 -52 -57 -52 -57 24 -18
c115 -91 273 -121 371 -72 78 39 120 102 120 179 0 56 -12 86 -52 128 -35 39
-43 42 -157 73 -102 27 -124 37 -124 60 0 48 89 59 164 19 26 -14 50 -25 54
-25 4 0 27 23 51 52 l45 51 -35 29 c-65 55 -215 83 -294 56z"/>
</g>
</svg>

</template>