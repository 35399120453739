import { createStore } from "vuex";

import users from "./modules/user.store";
import storeControl from "./modules/storeControl.store";

export default createStore({
  modules: {
    users,
    storeControl,
  },
});



// import { createStore } from 'vuex'
// export default createStore({
//   state: {
//     list: [],
//     categories: [],
//     choosenCategory: '',
//     sortedList: [],
//     headerCategories: [],
//     orderId: '',
//     isOrderFalied: false,
//     user: undefined,
//     cartTotal: 0,
//     storeControllInf: undefined
//   },
//   getters: {
//   },
//   mutations: {
//     SETLIST (state, payload) {
//       state.list = payload.data
//       state.categories = []
//       for (var i = 0; i < state.list.length; i++) {
//         if (!state.categories.includes(state.list[i].category)) {
//           state.categories.push(state.list[i].category)
          
//         } 
//         if (!state.headerCategories.includes(state.list[i].category)) {
//           state.headerCategories.push(state.list[i].category)
//         } 
//       }
//     },
//     SETISFALIED (state, payload) {
//       state.isOrderFalied = payload
//     },
//     SETUSER (state, payload) {
//       state.user = payload.user
//     }, 
//     ADDTOCART (state, payload) {
//       state.user.cart.products.push(payload.product)
//       state.user.cart.total = payload.total
//     },
//     UPDATECART (state, payload) {
//       console.log(payload)
//       state.user.cart = payload.cart
//     },
//     REMOVEFROMCART (state, payload) {
//       let cartProducts = state.user.cart.products
//       const foundIndex = cartProducts.findIndex(cartProducts => cartProducts._id === payload.id);
//       state.user.cart.products.splice(foundIndex, 1)
//       state.user.cart.total = payload.total
//     },
//     UPDATEADDRESS (state, payload) {
//       state.user.address = payload.address
//     },
//     UPDATEPHONENUMBER (state, payload) {
//       state.user.phoneNumber = payload.phoneNumber
//     },
//     SET_STORECONTROLL(state, paylaod) {
//       state.storeControllInf = paylaod.controll
//       console.log(state.storeControllInf)
//     },
//     async SETCARTEMPTY (state) {
//       setTimeout(function() {
//         state.user.cart = []
//     }, 1000);
//     }
//   },
//   actions: {
//     getList ({ commit }) {
//       return axios.get('/products/')
//         .then((res) => {
//           commit('SETLIST', { data: res.data })
//           return res.data
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     chooseCategory ({ commit }, data) {
//       return axios.get(`/products/category/${data}`)
//         .then((res) => {
//           commit('SETLIST', { data: res.data, category: data })
//           return res.data
//         })
//         .catch(err => {
//           return err
//         })
//     },
//     // eslint-disable-next-line
//     checkOut ({ commit, state }, payload) {
//       return axios.post(`/order/checkout/${state.user._id}`, { cart: state.user.cart, orderDetails: payload.orderDetails  })
//         .then(response => {
//           console.log(response)
//           return response.data.pageUrl
//         })
//         .catch(err => {
//           console.log(err)
//           return err
//         })
//     },
//     // eslint-disable-next-line
//     updateOrderStatus ({ commit }, payload) {
//       console.log(payload)
//       return axios.patch(`/order/${payload}`, { status: 'Оплачено' })
//         .then((res) => {
//           commit('SETISFALIED', false)
//           return res
//         })
//         .catch(() => {
//           commit('SETISFALIED', true)
//         })
//     },
//     // eslint-disable-next-line
//     getOrder ({}, payload) {
//       return axios.patch(`/order/${payload}`)
//         .then((res) => {
//           return res
//         })
//         .catch(() => {
//         })
//     },
//     async logIn ({ commit }, payload) {
//       return await axios.get(`/users/get/${payload}`)
//         .then((res) => {
//           return axios.post(`/users/verify/`, res.data)
//             .then((res) => {
//               commit('SETUSER', { user: res.data })
//               if (res.data.lastInvoiceId) {
//                  axios.get(`/order/checkout/check/${res.data.lastInvoiceId}`)
//               }
//               return payload
//             })
//             .catch(err => {
//               return err
//             })
//         })
//         .catch(() => {
//           return undefined
//         })
//     },
//     logOut ({ commit }) {
//       return commit('SETUSER', { user: undefined })
//     },
//     addToCart ({ commit, state }, payload) {
//       payload.weight = parseFloat(payload.weight)
//       return axios.post(`/users/cart/${state.user._id}`, payload)
//         .then((res) => {
//           return commit('UPDATECART', { cart: res.data })
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     updateInCart ({ state, commit}, payload) {
//       return axios.patch(`/users/cart/update/${state.user._id}`, payload)
//         .then((res) => {
//           return commit('UPDATECART', { cart: res.data })
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     removeFromCart ({ commit, state }, payload) {
//       return axios.delete(`/users/cart/${state.user._id}/${payload}`)
//         .then((res) => {
//           return commit('UPDATECART', { cart: res.data })
//         })
//         .catch(err => {
//           console.log(err)
//         })
      
//     },
//     updateUserAddress ({ commit, state }, payload) {
//       return axios.post(`/users/updateAddress/${state.user._id}`, payload)
//         .then((res) => {
//           return commit('UPDATEADDRESS', { address: res.data.address })
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     updatePhoneNumber ({ commit, state }, payload) {
//       return axios.post(`/users/updatePhoneNumber/${state.user._id}`, { phoneNumber: payload })
//         .then((res) => {
//           return commit('UPDATEPHONENUMBER', { phoneNumber: res.data.phoneNumber })
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     async setEmptyCart ({ commit }, payload) {
//       return await axios.delete(`/users/cart/${payload}/all`)
//         .then(() => {
//           return commit('SETCARTEMPTY')
//         })
//         .catch(err => {
//           console.log(err)
//         })
//     },
//     async getStoreControll({ commit }) {
//       return await axios.get(`/controlStore/get`)
//       .then((res) => {
//         commit("SET_STORECONTROLL", { controll : res.data });
//         return res.data
//       })
//       .catch(err => {
//         console.log(err)
//       })
//     },
//     async updateStoreStatus({ commit }, payload) {
//       return await axios.patch(`/controlStore/`, { storeControll: payload.storeControllInf })
//       .then((res) => {
//         commit("SET_STORECONTROLL", { controll : res.data });
//         return res.data
//       })
//       .catch(err => {
//         console.log(err)
//       })
//     }
//   },
//   modules: {
//   }
// })