import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueTelInput from 'vue-tel-input';
import GAuth from 'vue3-google-oauth2'
import Vue3TouchEvents from 'vue3-touch-events'
import 'vue-tel-input/vue-tel-input.css';

import "@fortawesome/fontawesome-free/css/all.min.css"

const gAuthOptions = { clientId: process.env.VUE_APP_CLIENID, plugin_name: "chat", scope: 'email', prompt: 'consent', fetch_basic_profile: false }

import vue3GoogleLogin from 'vue3-google-login'

createApp(App).use(GAuth, gAuthOptions, Vue3TouchEvents, vue3GoogleLogin, VueTelInput).use(store).use(router).mount('#app')
