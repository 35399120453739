import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_LINK
axios.defaults.headers.common['Token'] = process.env.VUE_APP_SECURE_TOKEN
axios.defaults.headers.post['Token'] = process.env.VUE_APP_SECURE_TOKEN

export default {
  namespaced: true,
  state: {
    isOrderFalied: false,
    user: undefined,
  },
  mutations: {
    // user
    SETISFALIED (state, payload) {
      state.isOrderFalied = payload
    },
    SETUSER (state, payload) {
      state.user = payload.user
    }, 
    UPDATEADDRESS (state, payload) {
      state.user.address = payload.address
    },
    UPDATEPHONENUMBER (state, payload) {
      state.user.phoneNumber = payload.phoneNumber
    },
    //cart
    ADDTOCART (state, payload) {
      state.user.cart.products.push(payload.product)
      state.user.cart.total = payload.total
    },
    UPDATECART (state, payload) {
      state.user.cart = payload.cart
    },
    REMOVEFROMCART (state, payload) {
      let cartProducts = state.user.cart.products
      const foundIndex = cartProducts.findIndex(cartProducts => cartProducts._id === payload.id);
      state.user.cart.products.splice(foundIndex, 1)
      state.user.cart.total = payload.total
    },
    async SETCARTEMPTY (state) {
      setTimeout(function() {
        if(state.user) state.user.cart = []
    }, 1000);
    }
  },
  actions: {
    //user
    // eslint-disable-next-line
    checkOut ({ commit, state }, payload) {
      const data = {
        cart : { products: state.user.cart.products}, 
        orderDetails: payload.orderDetails
      }
      return axios.post(`/order/checkout/${state.user._id}`, data)
        .then(response => {
          return response.data.pageUrl
        })
        .catch(err => {
          console.log(err)
          return err
        })
    },
    // eslint-disable-next-line
    updateOrderStatus ({ commit }, payload) {
      return axios.patch(`/order/${payload}`, { status: 'Оплачено' })
        .then((res) => {
          commit('SETISFALIED', false)
          return res
        })
        .catch(() => {
          commit('SETISFALIED', true)
        })
    },
    // eslint-disable-next-line
    getOrder ({}, payload) {
      return axios.patch(`/order/${payload}`)
        .then((res) => {
          return res
        })
        .catch(() => {
        })
    },
    async logIn ({ commit }, payload) {
      return await axios.get(`/users/get/${payload}`)
        .then((res) => {
          return axios.post(`/users/verify/`, res.data)
            .then((res) => {
              commit('SETUSER', { user: res.data })
              if (res.data.lastInvoiceId) {
                  axios.get(`/order/checkout/check/${res.data.lastInvoiceId}`)
              }
              return payload
            })
            .catch(err => {
              return err
            })
        })
        .catch(() => {
          return undefined
        })
    },
    logOut ({ commit }) {
      return commit('SETUSER', { user: undefined })
    },
    updateUserAddress ({ commit, state }, payload) {
      return axios.post(`/users/updateAddress/${state.user._id}`, payload)
        .then((res) => {
          return commit('UPDATEADDRESS', { address: res.data.address })
        })
        .catch(err => {
          console.log(err)
        })
    },
    updatePhoneNumber ({ commit, state }, payload) {
      return axios.post(`/users/updatePhoneNumber/${state.user._id}`, { phoneNumber: payload })
        .then((res) => {
          return commit('UPDATEPHONENUMBER', { phoneNumber: res.data.phoneNumber })
        })
        .catch(err => {
          console.log(err)
        })
    },
    //cart
    addToCart ({ commit, state }, payload) {
      payload.weight = parseFloat(payload.weight)
      return axios.post(`/users/cart/${state.user._id}`, payload)
        .then((res) => {
          return commit('UPDATECART', { cart: res.data })
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateInCart ({ state, commit}, payload) {
      return axios.patch(`/users/cart/update/${state.user._id}`, payload)
        .then((res) => {
          return commit('UPDATECART', { cart: res.data })
        })
        .catch(err => {
          console.log(err)
        })
    },
    removeFromCart ({ commit, state }, payload) {
      return axios.delete(`/users/cart/${state.user._id}/${payload}`)
        .then((res) => {
          return commit('UPDATECART', { cart: res.data })
        })
        .catch(err => {
          console.log(err)
        })
      
    },
    async setEmptyCart ({ commit }, payload) {
      return await axios.delete(`/users/cart/${payload}/all`)
        .then(() => {
          return commit('SETCARTEMPTY')
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}