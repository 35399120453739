import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CartView from '../views/CartView.vue'
import CompletedOrderView from '../views/CompletedOrder.vue'
import Terms from '../views/Terms.vue'
import TestView from '../views/Test.vue'
import User from '../views/UserView.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/:category?',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/checkout/:id',
    name: 'CompletedOrderView',
    component: CompletedOrderView
  },
  {
    path: '/user/terms',
    name: 'TermsView',
    component: Terms
  },
  {
    path: '/user/:path',
    name: 'UserView',
    component: User,
    beforeEnter: () => {
      if(!store.state.users.user) return '/'
    }
  },
  {
    path: '/payment/test',
    name: 'TestView',
    component: TestView,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
