<template>
  <head>
    <link
      rel="stylesheet"
      href="https://ecconnect.upc.ua/pub/utils/upc-payment-sdk/upc-payment.css"
    />
  </head>
  <p>text</p>
  <button @click="name">Click</button>
</template>

<script>
import { UpcPayment, UpcPaymentMode } from 'upc-payment-js';
export default {
  name: 'TestView',
  methods: {
    name() {
        let payment = new UpcPayment({
        commonProps: {
          merchantId: '1758136',
          terminalId: 'E7885986',
          signature: 'signature',
          currency: 980,
          paymentUrl: 'https://ecg.test.upc.ua/go/pay',
          locale: 'en',
          mode: UpcPaymentMode.ModalIframe,
        },
        iframeProps: {
          onSuccessPayment: () => {},
          onFailurePayment: () => {},
          onGoBackToSiteButtonClick: () => {},
          onPayMoreButtonClick: () => {},
        },
      });
      payment.pay({
        orderId: 'orderId',
        description: 'description',
        purchaseTime: 1678436363678,
        totalAmount: 1000,
      })
      .catch(err => {
        console.log(err)
      })

      
    }
  }
}
  
</script>
<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
